import React from "react";
import styled from "@emotion/styled";
import {
  Alert as MuiAlert,
  // Avatar,
  // Box,
  CircularProgress as MuiCircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Grid,
  //Typography,
  TextField,
  Divider as MuiDivider,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  sendCurrentChatMail,
  reset,
} from "../../../../../redux/slices/sendMailPreviousChat";
import ChatMessageComponent from "./ContactChatHistory/ChatMessageComponent";

const Divider = styled(MuiDivider)(spacing);
const Alert = styled(MuiAlert)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const ChatContainer = styled(Grid)`
  width: 100%;
  height: 65vh;
`;
const ChatMain = styled(Grid)``;

const ChatMessages = styled.div`
  overflow-y: scroll;
  height: calc(65vh - 94px);
`;

// const ChatMessage = styled.div`
//   margin: 30px;
//   text-align: ${(props) => props.position};
// `;

// const ChatMessageInner = styled.div`
//   display: inline-block;
// `;

// const ChatMessageTime = styled(Typography)`
//   text-align: right;
//   opacity: 0.8;
// `;

// const ChatMessageAvatar = styled(Avatar)`
//   position: relative;
//   display: inline-block;
//   width: 34px;
//   height: 34px;
//   margin-right: ${(props) => props.theme.spacing(2)};
// `;

// const ChatMessageBubble = styled.div`
//   display: inline-block;
//   margin-right: auto;
//   background: ${(props) =>
//     props.highlighted
//       ? props.theme.palette.secondary.main
//       : props.theme.palette.action.hover};
//   color: ${(props) =>
//     props.highlighted
//       ? props.theme.palette.common.white
//       : props.theme.palette.text.primary};
//   border-radius: 3px;
//   padding: ${(props) => props.theme.spacing(2)};
//   margin-bottom: ${(props) => props.theme.spacing(1)};
//   ${(props) => props.theme.shadows[1]};
// `;

// const ChatMessageBubbleName = styled(Typography)`
//   font-weight: ${(props) => props.theme.typography.fontWeightBold};
// `;

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

// function ChatMessageComponent({
//   name,
//   message,
//   time,
//   avatar,
//   position = "left",
// }) {
//   return (
//     <ChatMessage position={position}>
//       <ChatMessageInner>
//         <ChatMessageAvatar alt="Sunit Patil Lavender" src={avatar} />
//         <ChatMessageBubble highlighted={position === "right"}>
//           <Box>
//             <ChatMessageBubbleName variant="body1">
//               {name}
//             </ChatMessageBubbleName>
//           </Box>
//           <Typography variant="body2">{message}</Typography>
//         </ChatMessageBubble>
//         <ChatMessageTime variant="body2">{time}</ChatMessageTime>
//       </ChatMessageInner>
//     </ChatMessage>
//   );
// }

const Chat = ({ chatData }) => {
  return (
    <ChatContainer>
      <ChatMain item xs={12} md={12} lg={12}>
        <ChatMessages>
          {!!chatData &&
            chatData.messagesData.length > 0 &&
            chatData.messagesData.map((items, i) =>
              //items.content_type === 0 &&
              items.sender_type === 1 ? (
                <ChatMessageComponent
                  key={i}
                  name={
                    !!chatData &&
                    chatData.contactData[0] &&
                    (chatData.contactData[0].displayname ||
                      chatData.contactData[0].name)
                  }
                  avatar={
                    !!chatData &&
                    chatData.contactData[0] &&
                    (chatData.contactData[0].photo
                      ? chatData.contactData[0].photo
                      : "")
                  }
                  //avatar="/static/img/avatars/avatar-2.jpg"
                  message={items.content}
                  time={
                    ("0" + new Date(items.created_at).getHours()).slice(-2) +
                    ":" +
                    ("0" + new Date(items.created_at).getMinutes()).slice(-2)
                  }
                  position="left"
                  sender_type={items.sender_type}
                  content_type={!!items && items.content_type === 0 ? 0 : 1}
                  is_media={
                    !!items &&
                    !!items.media_type &&
                    (items.media_type === "" ? 0 : 1)
                  }
                  media_type={
                    !!items &&
                    !!items.media_type &&
                    (items.media_type === "" ? "" : items.media_type)
                  }
                  media_url={
                    !!items &&
                    !!items.media_type &&
                    !!items.media_path &&
                    (items.media_type === "" ? "" : items.media_path)
                  }
                />
              ) : (
                <ChatMessageComponent
                  key={i}
                  name={
                    (!!items &&
                      items.chatassignsData.length !== 0 &&
                      items.chatassignsData[0].accountagentData[0]
                        .displayname) ||
                    items.chatassignsData[0].accountagentData[0].name
                  }
                  avatar={
                    !!items &&
                    items.chatassignsData.length !== 0 &&
                    !!items.chatassignsData[0].accountagentData[0]
                      ? items.chatassignsData[0].accountagentData[0].avatar
                      : ""
                  }
                  message={items.content}
                  time={
                    ("0" + new Date(items.created_at).getHours()).slice(-2) +
                    ":" +
                    ("0" + new Date(items.created_at).getMinutes()).slice(-2)
                  }
                  position="right"
                  content_type={!!items && items.content_type === 0 ? 0 : 1}
                  is_media={
                    !!items &&
                    !!items.message_type &&
                    (items.message_type === "" ? 0 : 1)
                  }
                  media_type={
                    !!items &&
                    (items.message_type === "" ? "" : items.media_type)
                  }
                  media_url={
                    !!items &&
                    (items.message_type === "" ? "" : items.media_path)
                  }
                />
              )
            )}
        </ChatMessages>
      </ChatMain>
    </ChatContainer>
  );
};

const ChatContactHistory = ({
  openAdd,
  handleAddClose,
  handleAddClickOpen,
  email,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.sendMail_PreviousChat);

  const [sendEmail, setSendEmail] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [errors, setErrors] = React.useState("");
  React.useEffect(() => {
    setSendEmail(email);
  }, [email]);

  const SendMail = async (conversation_id) => {
    setIsLoading(true);
    try {
      var payload = {
        conversation_id: openAdd.data._id,
        email: sendEmail,
      };
      await dispatch(sendCurrentChatMail(payload));
      setIsLoading(false);
      setIsError(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setErrors("Error ");
    }
    await timeOut(4000);
    await dispatch(reset());
    setSendEmail("");
  };

  return (
    <Dialog
      open={openAdd.Open}
      onClose={handleAddClose}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="form-dialog-title">
        {!!openAdd.data.contactData &&
          `The previous chat of ` + openAdd.data.contactData[0].name}
        <DialogContentText>
          {!!openAdd.data &&
            ` Date: ` +
              ("0" + new Date(openAdd.data.created_at).getDate()).slice(-2) +
              "/" +
              ("0" + (new Date(openAdd.data.created_at).getMonth() + 1)).slice(
                -2
              ) +
              "/" +
              new Date(openAdd.data.created_at).getFullYear() +
              " " +
              ("0" + new Date(openAdd.data.created_at).getHours()).slice(-2) +
              ":" +
              ("0" + new Date(openAdd.data.created_at).getMinutes()).slice(-2)}
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <Divider />
        <Chat chatData={!!openAdd.data && openAdd.data} />

        <Divider />
        {isLoading === true && <CircularProgress m={2} />}
        {isError === true && (
          <Alert mb={4} variant="filled" severity="error">
            {!!errors && errors}
          </Alert>
        )}
        {!!data && !!data.status && data.status !== 200 && (
          <Alert variant="filled" severity="error">
            {!!data && !!data.message && data.message}
          </Alert>
        )}
        {!!data && data.status === 200 && (
          <Alert variant="filled" severity="success">
            "Your mail has been sent successfuly"
          </Alert>
        )}
        <DialogContentText mt={3}>
          Send the transcript to email address
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          value={sendEmail}
          onChange={(e) => setSendEmail(e.target.value)}
          fullWidth
          mt={3}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            SendMail(openAdd.data._id);
          }}
          color="primary"
        >
          Send email
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ChatContactHistory;
