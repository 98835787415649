import React from "react";
import styled from "@emotion/styled";
import "bootstrap/dist/css/bootstrap.min.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "react-h5-audio-player/lib/styles.css";
import useSound from "use-sound";
import {
  Alert as MuiAlert,
  Box,
  Grid,
  TextField as MuiTextField,
  Divider as MuiDivider,
  Popover as MuiPopover,
  LinearProgress as MuiLinearProgress,
} from "@mui/material";
//import HelpIcon from "@mui/icons-material/Help";
import { spacing } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
//import moment from "moment";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import AttachmentIcon from "@mui/icons-material/Attachment";
import Button from "@mui/material/Button";
import ChatMessageComponent from "./ChatMessageComponent";
import { useDispatch, useSelector } from "react-redux";
import EmojiPicker from "emoji-picker-react";
//import notificationSound from "../../audio/notification.mp3";
//import sendingSound from "../../audio/sending.mp3";
//import sendingSound from "https://liveagentapp.s3.ap-northeast-1.amazonaws.com/uploads/1685532673296_sending.mp3";
//import sendingSound from "@/static/img/samples//sending.mp3";
import {
  uploadFileData,
  uploadPrivateFileData,
} from "../../../../redux/slices/chatSettings";
import { fetchAllCannedResponses } from "../../../../redux/slices/cannedResponses";
import { fetchTodayschat } from "../../../../redux/slices/socketsSlice";
import { yellow } from "@mui/material/colors";
import ChatGTP from "./ChatGTP";
//import cp from "child_process";
//import { fun_resetChatFromoneSpocData } from "../../../../redux/slices/socketsSlice";
//import moment from "moment";

const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);
const LinearProgress = styled(MuiLinearProgress)(spacing);
const timeOut = (time) => new Promise((res) => setTimeout(res, time));
const Alert = styled(MuiAlert)(spacing);
//console.log("sendingSound == ", sendingSound);
const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const ChatMessages = styled.div`
  overflow-y: scroll;
  // height: calc(60vh - 94px);
`;

const ChatInput = styled(Grid)`
  max-height: 60px;
  padding: ${(props) => props.theme.spacing(5)};
`;
const ChatAction = styled(`span`)`
  background-color: ${(props) => props.theme.sidebar.header.chip.color};
  padding: ${(props) => props.theme.spacing(2)};
  border-radius: 10px;
  margin-right: 20px;
  cursor: pointer;
`;
const ChatActionNote = styled(`span`)`
  background-color: ${(props) => props.theme.sidebar.header.chipNote.color};
  padding: ${(props) => props.theme.spacing(2)};
  border-radius: 10px;
  margin-right: 20px;
  cursor: pointer;
`;

const ChatMessagePannel = ({ socket }) => {
  //const [notificationSPlay] = useSound(notificationSound);
  const dispatch = useDispatch();
  const bottomRef = React.useRef(null);
  //const [sendingPlay] = useSound(sendingSound);
  const [sendingPlay] = useSound(
    "https://liveagentapp-in.s3.ap-south-1.amazonaws.com/uploads/1720614091715_new-notification-7-210334.mp3"
  );
  const [key, setKey] = React.useState("reply");
  const {
    conversationData,
    conversationId,
    botId,
    orgId,
    channelId,
    contactId,
    mute,
    contactData,
    fileData,
    filePrivateData,
    uploadFilestatus,
    uploadPrivateFilestatus,
    //reset_fileData,
  } = useSelector((state) => state.chatSettings);
  const { chatFromoneSpocData, todaysChat } = useSelector(
    (state) => state.socketsSlice
  );

  // console.log("Chat Message fileData == ", fileData);
  // console.log("Chat Message uploadFilestatus == ", uploadFilestatus);
  const { contactUpdateData } = useSelector((state) => state.socketsSlice);
  const user = JSON.parse(localStorage.getItem("user"));
  var [chatAssignee, setChatAssignee] = React.useState("");
  var [chatAssigneeCount, setChatAssigneeCount] = React.useState(0);

  var [conData, setConData] = React.useState([]);
  var [chatData, setChatData] = React.useState([]);
  var [chatMsg, setChatMsg] = React.useState("");
  var [chatPrivateMsg, setChatPrivateMsg] = React.useState("");
  var [agent, setAgent] = React.useState("");
  var [file, setFile] = React.useState("");
  var [filePrivate, setFilePrivate] = React.useState("");
  var [fileSize, setFileSize] = React.useState(false);
  var [filePrivateSize, setFilePrivateSize] = React.useState(false);

  const [selectedFile, setSelectedFile] = React.useState();
  const [selectedPrivateFile, setSelectedPrivateFile] = React.useState();
  const [isFilePicked, setIsFilePicked] = React.useState(false);
  const [isFilePickedPrivate, setIsFilePickedPrivate] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [process, setProcess] = React.useState("success");
  const [privateProcess, setPrivateProcess] = React.useState("success");
  const ref = React.useRef(null);
  const [progress, setProgress] = React.useState(0);

  const [buffer, setBuffer] = React.useState(10);
  const progressRef = React.useRef(() => {});

  React.useEffect(() => {
    !!uploadFilestatus && setProcess(uploadFilestatus);
    !!uploadFilestatus &&
      uploadFilestatus === "error" &&
      setIsFilePicked(false);
    const setTimeOutFun = async () => {
      await timeOut(2000);
      setProcess("success");
    };
    !!uploadFilestatus && uploadFilestatus === "error" && setTimeOutFun();
  }, [uploadFilestatus]);

  React.useEffect(() => {
    setPrivateProcess(uploadPrivateFilestatus);
  }, [uploadPrivateFilestatus]);

  React.useEffect(() => {
    dispatch(fetchTodayschat());
    setProcess("success");
    handleFileClose();
    handleFilePrivateClose();
  }, [dispatch, conversationId]);

  React.useEffect(() => {
    dispatch(fetchTodayschat());
  }, [dispatch, conversationId, contactUpdateData]);

  React.useEffect(() => {
    //console.log("  mute === true && sendingPlay(); ");
    //mute === true && sendingPlay();
    //sendingPlay();
    setChatAssignee("");
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) =>
          item._id === conversationId &&
          parseInt(item.status) === 0 &&
          setChatAssignee(item.chatAssign[0].assignee_id)
      );
  }, [todaysChat, conversationId]);

  // check chat asignee Count
  React.useEffect(() => {
    setChatAssigneeCount([]);
    // setChatMsg("");
    !!todaysChat &&
      !!todaysChat.data &&
      todaysChat.data.map(
        (item) =>
          item._id === conversationId &&
          parseInt(item.status) === 0 &&
          setChatAssigneeCount(item.chatAssign.length)
      );
  }, [todaysChat, conversationId]);

  // CLear Chat Message if Select New Chat
  React.useEffect(() => {
    setChatMsg("");
    setChatPrivateMsg("");
  }, [conversationId]);

  function onClick(emojiData, event) {
    setChatMsg((e) => e !== null && e + emojiData.emoji);
  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Close Emoji Picker

  React.useEffect(() => {
    setAgent(JSON.parse(localStorage.getItem("user")));
  }, []);

  //console.log("mute == ", mute);
  React.useEffect(() => {
    !!chatFromoneSpocData &&
      chatFromoneSpocData.sender_type === 1 &&
      mute === true &&
      sendingPlay();
    !!chatFromoneSpocData &&
      chatFromoneSpocData.length !== 0 &&
      setChatData((chatData) => [...chatData, chatFromoneSpocData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatFromoneSpocData]);

  React.useEffect(() => {
    !!conversationData &&
      conversationData.length !== 0 &&
      setConData(conversationData);
    setChatData("");
  }, [conversationData]);

  React.useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatData]);

  React.useEffect(() => {
    setFile(fileData);
  }, [fileData]);

  React.useEffect(() => {
    setFilePrivate(filePrivateData);
  }, [filePrivateData]);

  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleFileClose = () => {
    setFile("");
    setIsFilePicked(false);
  };

  const handleFilePrivateClose = () => {
    setFilePrivate("");
    setIsFilePickedPrivate(false);
  };

  const changeHandler = async (event) => {
    if (parseInt(event.target.files[0].size) > 5242855) {
      setFileSize(true);
      await timeOut(2000);
      setFileSize(false);
    } else {
      // dispatch(reset_fileData());
      setFile("");
      setSelectedFile();
      setSelectedFile(event.target.files[0]);
      setIsFilePicked(true);
      var obj = {
        file: event.target.files[0],
        channel_id: channelId,
      };
      //await dispatch(uploadFileData(event.target.files[0], "channel_id"));
      await dispatch(uploadFileData(obj));
    }
    event.target.value = "";
  };

  const changePrivateHandler = async (event) => {
    if (parseInt(event.target.files[0].size) > 5242855) {
      setFilePrivateSize(true);
      await timeOut(2000);
      setFilePrivateSize(false);
    } else {
      // dispatch(reset_fileData());
      setFilePrivate("");
      setSelectedPrivateFile();
      setSelectedPrivateFile(event.target.files[0]);
      setIsFilePickedPrivate(true);
      await dispatch(uploadPrivateFileData(event.target.files[0]));
    }
    event.target.value = "";
  };

  var rows = [];
  var rowsData = [];

  function createData(short_code, content) {
    return { short_code, content };
  }

  const { data: cannedResponses } = useSelector(
    (state) => state.cannedResponses
  );

  React.useEffect(() => {
    dispatch(fetchAllCannedResponses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterCanned = async (chatMsg) => {
    rowsData =
      !!cannedResponses && !!cannedResponses.data
        ? cannedResponses.data.map((item, i) =>
            createData(item.short_code, item.content)
          )
        : [];
    rows =
      chatMsg === ""
        ? []
        : rowsData.filter((i) =>
            i.short_code.toLowerCase().includes(chatMsg.toLowerCase())
          );
    return rows;
  };

  // console.log("ChatMessage  channelId == ", channelId);
  const sendMessage = async (media, mimetype) => {
    //console.log("sendMessage 12 contactData ", JSON.stringify(contactData));
    var canned = await filterCanned(chatMsg);
    var msg = !!canned && canned.length !== 0 ? canned[0].content : chatMsg;
    var messagePayload = {
      data: {
        flag: "continue",
        channel_id: channelId,
        bot_id: botId,
        org_id: orgId,
        account_id: localStorage.getItem("atua"),
        conversation_id: conversationId,
        agent_id: agent.uid,
        agentName: agent.displayname,
        assign_id: "",
        contact_id: contactId,
        contact: contactData,
        isPrivate: 0,
        message: [
          {
            content_type: 0,
            content: msg,
            media: {
              media_type: isFilePicked === true && !!mimetype ? mimetype : "",
              media_path: isFilePicked === true && !!media ? media : "",
            },
            message_type: isFilePicked === true ? "media" : "text",
          },
        ],
      },
      position: "right",
      sender_type: 0,
    };
    //https://www.shutterstock.com/image-photo/focused-woman-wearing-headphones-using-260nw-1395298487.jpg

    socket.current.emit("chatSentFromApp", messagePayload);
    setChatMsg("");
    //}
  };

  const handleSendMessage = async () => {
    var mimetype = !!file && (!!file.mimetype ? file.mimetype.split("/") : "");
    var media = !!file && (!!file.data ? file.data : "");

    if (!!chatMsg && chatMsg.length > 0 && !!media && media.length > 0) {
      await sendMessage(media, mimetype[0]);
    } else if (
      (!!chatMsg && chatMsg.trim() !== "") ||
      (!!media && media.length > 0)
    ) {
      await sendMessage(media, mimetype[0]);
    }
    setSelectedFile("");
    setIsFilePicked(false);
    media = "";
    setFile("");
  };

  const handleSendPrivateMessage = async () => {
    var mimetype =
      !!filePrivate &&
      (!!filePrivate.mimetype ? filePrivate.mimetype.split("/") : "");
    var media = !!filePrivate && (!!filePrivate.data ? filePrivate.data : "");
    //chatPrivateMsg
    if (
      !!chatPrivateMsg &&
      chatPrivateMsg.length > 0 &&
      !!media &&
      media.length > 0
    ) {
      await sendPrivateMessage(media, mimetype[0]);
    } else if (
      (!!chatPrivateMsg && chatPrivateMsg.trim() !== "") ||
      (!!media && media.length > 0)
    ) {
      await sendPrivateMessage(media, mimetype[0]);
    }

    setSelectedPrivateFile("");
    setIsFilePickedPrivate(false);
    media = "";
    setFilePrivate("");
  };
  const sendPrivateMessage = async (media, mimetype) => {
    var msg = chatPrivateMsg;
    var messagePayload = {
      data: {
        flag: "note",
        channel_id: channelId,
        bot_id: botId,
        org_id: orgId,
        account_id: localStorage.getItem("atua"),
        conversation_id: conversationId,
        agent_id: agent.uid,
        agentName: agent.displayname,
        assign_id: "",
        contact_id: contactId,
        contact: contactData,
        message: [
          {
            content_type: 11,
            content: msg,
            media: {
              media_type:
                isFilePickedPrivate === true && !!mimetype ? mimetype : "",
              media_path: isFilePickedPrivate === true && !!media ? media : "",
            },
            message_type: isFilePickedPrivate === true ? "media" : "text",
          },
        ],
      },
      position: "right",
      sender_type: 0,
    };
    socket.current.emit("chatSentFromApp", messagePayload);
    setChatPrivateMsg("");
  };

  //Chat GTP
  var [openChatGTP, setOpenChatGTP] = React.useState({
    Open: false,
  });

  var handleClickOpenChatGTP = () => {
    setOpenChatGTP({
      Open: true,
    });
  };

  var handleCloseChatGTP = () => {
    setOpenChatGTP({
      Open: false,
    });
  };

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        className="App"
      >
        <div className="App">
          <EmojiPicker onEmojiClick={onClick} autoFocusSearch={false} />
        </div>
      </Popover>

      <ChatMessages className="chatoutput-section">
        {!!conData &&
          conData.length > 0 &&
          conData[0]._id === conversationId &&
          conData[0].chat.map((item, i) =>
            item.sender_type === 1 ? (
              <ChatMessageComponent
                key={i}
                name={!!item && item.chatContact[0].name}
                avatar="/static/img/avatars/avatar-2.jpg"
                message={!!item && item.content}
                time={
                  ("0" + new Date(item.created_at).getHours()).slice(-2) +
                  ":" +
                  ("0" + new Date(item.created_at).getMinutes()).slice(-2)
                }
                position="left"
                content_type={!!item && item.content_type}
                is_media={
                  !!item && (!!item.message_type ? item.message_type : "")
                }
                media_type={
                  !!item && (!!item.media_type ? item.media_type : "")
                }
                media_url={!!item && (!!item.media_path ? item.media_path : "")}
              />
            ) : (
              <ChatMessageComponent
                key={i}
                name={
                  !!item &&
                  (!!item.chatAgent[0]
                    ? item.chatAgent[0].displayname
                    : item.chatAgent[0].name)
                }
                avatar="/static/img/avatars/avatar-2.jpg"
                message={!!item && item.content}
                content_type={!!item && item.content_type}
                time={
                  ("0" + new Date(item.created_at).getHours()).slice(-2) +
                  ":" +
                  ("0" + new Date(item.created_at).getMinutes()).slice(-2)
                }
                position="right"
                is_media={
                  !!item && (!!item.message_type ? item.message_type : "")
                }
                media_type={
                  !!item && (!!item.media_type ? item.media_type : "")
                }
                media_url={!!item && (!!item.media_path ? item.media_path : "")}
              />
            )
          )}

        {!!chatData &&
          chatData.length !== 0 &&
          chatData.map(
            (item, i) =>
              !!item.data &&
              item.data.conversation_id === conversationId &&
              (item.sender_type === 1 ? (
                <ChatMessageComponent
                  key={i}
                  name={
                    !!item &&
                    !!item.data &&
                    !!item.data.contact &&
                    item.data.contact.name
                  }
                  avatar="/static/img/avatars/avatar-2.jpg"
                  message={
                    !!item &&
                    !!item.data &&
                    !!item.data.message &&
                    item.data.message[0].content
                  }
                  time={
                    new Date(Date.now()).getHours() +
                    ":" +
                    new Date(Date.now()).getMinutes()
                  }
                  position="left"
                  sender_type={item.sender_type}
                  content_type={
                    !!item &&
                    !!item.data &&
                    !!item.data.message &&
                    item.data.message[0].content_type
                  }
                  is_media={
                    !!item &&
                    !!item.data &&
                    !!item.data.message &&
                    (item.data.message[0].message_type === "text" ? 0 : 1)
                  }
                  media_type={
                    !!item &&
                    !!item.data &&
                    !!item.data.message &&
                    (item.data.message[0].message_type === "text"
                      ? ""
                      : item.data.message[0].media.media_type)
                  }
                  media_url={
                    !!item &&
                    !!item.data &&
                    !!item.data.message &&
                    (item.data.message[0].message_type === "text"
                      ? ""
                      : item.data.message[0].media.media_path)
                  }
                />
              ) : (
                <ChatMessageComponent
                  key={i}
                  name={!!item && !!item.data && item.data.agentName}
                  avatar="/static/img/avatars/avatar-2.jpg"
                  message={
                    !!item &&
                    !!item.data &&
                    !!item.data.message &&
                    item.data.message[0].content
                  }
                  time={
                    !!item &&
                    !!item.data &&
                    !!item.data.message &&
                    (
                      "0" + new Date(item.data.message[0].created_at).getHours()
                    ).slice(-2) +
                      ":" +
                      (
                        "0" +
                        new Date(item.data.message[0].created_at).getMinutes()
                      ).slice(-2)
                  }
                  position="right"
                  content_type={
                    !!item &&
                    !!item.data &&
                    !!item.data.message &&
                    item.data.message[0].content_type
                  }
                  is_media={
                    !!item &&
                    !!item.data &&
                    !!item.data.message &&
                    (item.data.message[0].message_type === "text" ? 0 : 1)
                  }
                  media_type={
                    !!item &&
                    !!item.data &&
                    !!item.data.message &&
                    (item.data.message[0].message_type === "text"
                      ? ""
                      : item.data.message[0].media.media_type)
                  }
                  media_url={
                    !!item &&
                    !!item.data &&
                    !!item.data.message &&
                    (item.data.message[0].message_type === "text"
                      ? ""
                      : item.data.message[0].media.media_path)
                  }
                />
              ))
          )}

        <div ref={bottomRef} />
      </ChatMessages>
      <div className="chatinput-section">
        <Divider sx={{ borderBottomWidth: 2 }} />

        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab
            eventKey="reply"
            title="Reply"
            style={{ backgroundColor: "#fff" }}
          >
            {!!process && process === "loading" && (
              <LinearProgress
                my={2}
                variant="buffer"
                value={progress}
                valueBuffer={buffer}
                color="success"
              />
            )}
            {isFilePicked && (
              <ChatInput container className="attachedFile-section">
                <div className="attached-File">
                  {selectedFile.name}
                  <CloseIcon
                    style={{ marginLeft: "50px" }}
                    onClick={
                      !!process && process === "success" && handleFileClose
                    }
                  />
                </div>
              </ChatInput>
            )}
            {fileSize && (
              <Alert mb={4} severity="error">
                The file is too large. The total size is more than 5MB!
              </Alert>
            )}
            {!!process && process === "error" && (
              <Alert mb={4} severity="error">
                {!!fileData && fileData.message}
              </Alert>
            )}
            <ChatInput container style={{ marginBottom: "15px" }}>
              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  variant="outlined"
                  label="Type your message"
                  onChange={(e) => setChatMsg(e.target.value)}
                  // onChange={(e) => setSelectedEmoji(e.target.value)}
                  value={chatMsg}
                  multiline
                  rows={2}
                  id="txtMsg"
                  fullWidth
                  onKeyDown={(e) => {
                    if (
                      !!process &&
                      process === "success" &&
                      (!!chatAssigneeCount && chatAssigneeCount > 1
                        ? e.keyCode === 13 && !e.shiftKey // Send message on Enter without Shift
                        : !!chatAssignee &&
                          chatAssignee === user.uid &&
                          e.keyCode === 13 &&
                          !e.shiftKey) // Send message on Enter without Shift
                    ) {
                      e.preventDefault(); // Prevent the default behavior of Enter
                      handleSendMessage();
                    }
                    if (e.key === "Enter" && e.shiftKey) {
                      // Prevent the default behavior of the Enter key
                      e.preventDefault();
                      // Add a newline character to the input value
                      setChatMsg(chatMsg + "\n");
                    }
                  }}
                />
              </Grid>
            </ChatInput>
            <ChatInput container>
              <Grid item style={{ flexGrow: 1 }}>
                <Box ml={2} mt={2} className="chatButtons">
                  <ChatAction>
                    <EmojiEmotionsIcon
                      onClick={handleOpen}
                      sx={{ color: yellow[900] }}
                    />
                  </ChatAction>
                  {!!process && process === "success" ? (
                    <ChatAction>
                      <input
                        style={{ display: "none" }}
                        id="raised-button-file"
                        //multiple
                        type="file"
                        // onChange={(e) => setFiles(e.target.files[0])}
                        onChange={changeHandler}
                      />
                      <label htmlFor="raised-button-file">
                        <AttachmentIcon
                          htmlFor="raised-button-file"
                          color="primary"
                        />
                      </label>
                    </ChatAction>
                  ) : (
                    <ChatAction>
                      <AttachmentIcon
                        htmlFor="raised-button-file"
                        color="primary"
                      />
                    </ChatAction>
                  )}
                </Box>
              </Grid>
              <Grid item>
                <Box ml={2}>
                  {!!chatAssigneeCount && chatAssigneeCount > 1 ? (
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => {
                        !!process &&
                          process === "success" &&
                          handleSendMessage();
                      }}
                    >
                      Send
                    </Button>
                  ) : (
                    !!chatAssignee &&
                    chatAssignee === user.uid && (
                      <Button
                        variant="contained"
                        color="info"
                        onClick={() => {
                          !!process &&
                            process === "success" &&
                            handleSendMessage();
                        }}
                      >
                        Send
                      </Button>
                    )
                  )}
                </Box>
              </Grid>
            </ChatInput>
          </Tab>
          {!!chatAssigneeCount && chatAssigneeCount > 1 ? (
            <Tab
              eventKey="note"
              title="Note"
              style={{ backgroundColor: "#fff" }}
            >
              {!!privateProcess && privateProcess === "loading" && (
                <LinearProgress
                  my={2}
                  variant="buffer"
                  value={progress}
                  valueBuffer={buffer}
                  color="warning"
                />
              )}
              {isFilePickedPrivate && (
                <ChatInput container>
                  {selectedPrivateFile.name}
                  <CloseIcon
                    style={{ marginLeft: "50px" }}
                    onClick={handleFilePrivateClose}
                  />
                </ChatInput>
              )}
              <ChatInput container>
                <Grid item style={{ flexGrow: 1 }}>
                  <TextField
                    variant="outlined"
                    label="Type your message"
                    fullWidth
                    onChange={(e) => setChatPrivateMsg(e.target.value)}
                    value={chatPrivateMsg}
                    onKeyDown={(e) => {
                      e.keyCode === 13 && handleSendPrivateMessage();
                    }}
                  />
                </Grid>
              </ChatInput>
              <ChatInput container>
                <Grid item style={{ flexGrow: 1 }}>
                  <Box ml={2} mt={2} className="chatButtons">
                    <ChatActionNote>
                      <input
                        style={{ display: "none" }}
                        id="raised-button-filePrivate"
                        //multiple
                        type="file"
                        // onChange={(e) => setFiles(e.target.files[0])}
                        onChange={changePrivateHandler}
                      />
                      <label htmlFor="raised-button-filePrivate">
                        <AttachmentIcon htmlFor="raised-button-filePrivate" />
                      </label>
                    </ChatActionNote>
                  </Box>
                </Grid>
                <Grid item>
                  <Box ml={2}>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        handleSendPrivateMessage();
                      }}
                    >
                      Send
                    </Button>
                  </Box>
                </Grid>
              </ChatInput>
            </Tab>
          ) : (
            !!chatAssignee &&
            chatAssignee === user.uid && (
              <Tab
                eventKey="note"
                title="Note"
                style={{ backgroundColor: "#fff" }}
              >
                {!!privateProcess && privateProcess === "loading" && (
                  <LinearProgress
                    my={2}
                    variant="buffer"
                    value={progress}
                    valueBuffer={buffer}
                    color="warning"
                  />
                )}
                {isFilePickedPrivate && (
                  <ChatInput container>
                    {selectedPrivateFile.name}
                    <CloseIcon
                      style={{ marginLeft: "50px", cursor: "pointer" }}
                      onClick={
                        !!privateProcess &&
                        privateProcess === "success" &&
                        handleFilePrivateClose
                      }
                    />
                  </ChatInput>
                )}
                {filePrivateSize && (
                  <Alert mb={4} severity="error">
                    The file is too large. The total size is more than 5MB!
                  </Alert>
                )}
                <ChatInput container style={{ marginBottom: "15px" }}>
                  <Grid item style={{ flexGrow: 1 }}>
                    <TextField
                      variant="outlined"
                      label="Type your message"
                      fullWidth
                      id="txtchatPrivateMsg"
                      multiline
                      rows={2}
                      onChange={(e) => setChatPrivateMsg(e.target.value)}
                      value={chatPrivateMsg}
                      onKeyDown={(e) => {
                        if (
                          !!privateProcess &&
                          privateProcess === "success" &&
                          e.keyCode === 13 &&
                          !e.shiftKey // Send message on Enter without Shift
                        ) {
                          e.preventDefault(); // Prevent the default behavior of Enter
                          handleSendPrivateMessage();
                        }
                        if (e.key === "Enter" && e.shiftKey) {
                          // Prevent the default behavior of the Enter key
                          e.preventDefault();
                          // Add a newline character to the input value
                          setChatPrivateMsg(chatPrivateMsg + "\n");
                        }
                      }}
                    />
                  </Grid>
                </ChatInput>
                <ChatInput container>
                  <Grid item style={{ flexGrow: 1 }}>
                    <Box ml={2} mt={2} className="chatButtons">
                      {/* <ChatActionNote>
                  <SentimentSatisfiedAltIcon />
                </ChatActionNote> */}
                      {!!privateProcess && privateProcess === "success" ? (
                        <ChatActionNote>
                          <input
                            style={{ display: "none" }}
                            id="raised-button-filePrivate"
                            multiple
                            type="file"
                            // onChange={(e) => setFiles(e.target.files[0])}
                            onChange={changePrivateHandler}
                          />

                          <label htmlFor="raised-button-filePrivate">
                            <AttachmentIcon htmlFor="raised-button-filePrivate" />
                          </label>
                        </ChatActionNote>
                      ) : (
                        <ChatActionNote>
                          <AttachmentIcon htmlFor="raised-button-filePrivate" />
                        </ChatActionNote>
                      )}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box ml={2}>
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          !!privateProcess &&
                            privateProcess === "success" &&
                            handleSendPrivateMessage();
                        }}
                      >
                        Send
                      </Button>
                    </Box>
                  </Grid>
                </ChatInput>
              </Tab>
            )
          )}
        </Tabs>
      </div>
      <ChatGTP
        openChatGTPAdd={openChatGTP}
        handleCloseChatGTP={handleCloseChatGTP}
        handleClickOpenChatGTP={handleClickOpenChatGTP}
      />
    </>
  );
};

export default ChatMessagePannel;
